import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Column } from '../Grid';

const Section = ({
  className,
  containerAs,
  containerId,
  containerPaddingX,
  containerPaddingY,
  containerBackgroundColor,
  rowBackgroundColor,
  columnMaxWidth,
  children,
}) => (
  <Container
    className={className}
    as={containerAs}
    id={containerId}
    paddingX={containerPaddingX}
    paddingY={containerPaddingY}
    backgroundColor={containerBackgroundColor}
  >
    <Row
      justifyContent="center"
      backgroundColor={rowBackgroundColor}
    >
      <Column maxWidth={columnMaxWidth}>
        {children}
      </Column>
    </Row>
  </Container>
);

Section.propTypes = {
  className: PropTypes.string,
  containerAs: PropTypes.string,
  containerId: PropTypes.string,
  containerPaddingX: PropTypes.string,
  containerPaddingY: PropTypes.string,
  containerBackgroundColor: PropTypes.string,
  rowBackgroundColor: PropTypes.string,
  columnMaxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  className: '',
  containerAs: 'div',
  containerId: '',
  containerPaddingX: '16px',
  containerPaddingY: '16px',
  containerBackgroundColor: '#fff',
  rowBackgroundColor: 'transparent',
  columnMaxWidth: '100%',
};

export default Section;
