import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => rem(props.paddingY)} ${props => rem(props.paddingX)};
  background-color: ${props => props.backgroundColor};
`;

Container.propTypes = {
  paddingX: PropTypes.string,
  paddingY: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Container.defaultProps = {
  paddingX: '16px',
  paddingY: '16px',
  backgroundColor: '#eee',
};


export default Container;
