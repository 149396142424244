import PropTypes from 'prop-types';
import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  max-width: ${props => props.maxWidth};
`;

Column.propTypes = {
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  maxWidth: PropTypes.string,
};

Column.defaultProps = {
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  maxWidth: '100%',
};

export default Column;
