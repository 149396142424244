import { css } from 'styled-components';

import config from '../config';

const { breakpoints, rootFontSize } = config;

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label] / rootFontSize}rem) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
