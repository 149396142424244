import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, lighten } from 'polished';

import getTopOffset from '../../utils/getTopOffset';
import config from '../../config';

const { colors, fonts } = config;

const Container = styled.aside`
  display: ${props => props.display};
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 80px;
  top: ${rem('80px')};
  bottom: 0;
  right: 0;
  width: 240px;
  width: ${rem('240px')};
  height: calc(100vh - 80px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background-color: ${props => props.backgroundColor};
  transform: translateX(${props => props.translateX});
  transition: transform ease-in-out 0.5s;
`;

Container.propTypes = {
  display: PropTypes.string,
  translateX: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Container.defaultProps = {
  display: 'none',
  translateX: '100%',
  backgroundColor: colors.dark || '#fff',
};

const MenuLink = styled.a`
  display: flex;
  justify-content: center;
  padding: 16px;
  padding: ${rem('16px')};
  color: ${props => props.color};
  font-family: ${props => props.fontFamily};
  font-size: 32px;
  font-size: ${rem('32px')};
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;

  &:hover {
    color: #fff;
    background-color: ${props => lighten(0.05, props.hoverBackgroundColor)};
  }
`;

MenuLink.propTypes = {
  color: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  fontFamily: PropTypes.string,
};

MenuLink.defaultProps = {
  color: colors.accentColor || '#fff',
  hoverBackgroundColor: colors.dark || '#222',
  fontFamily: fonts.accentFontFamily || 'inherit',
};

class RightMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'none',
      translateX: '100%',
    };
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.show !== props.show) {
      this.toggleDisplayState();
      this.toggleTranslateXState();
    }
  }

  toggleTranslateXState = () => {
    const { translateX } = this.state;

    if (translateX === '100%') {
      return this.setState({
        translateX: '0%',
      });
    }

    return this.setState({
      translateX: '100%',
    });
  }

  toggleDisplayState = () => {
    const { display } = this.state;

    if (display === 'none') {
      return this.setState({
        display: 'flex',
      });
    }

    return this.setState({
      display: 'none',
    });
  }

  scrollToId = (e, str) => {
    e.preventDefault();
    const el = document.getElementById(str);
    window.scroll({
      top: getTopOffset(el) - 80,
      behavior: 'smooth',
    });
  }

  render() {
    const { state } = this;

    return (
      <Container
        display={state.display}
        translateX={state.translateX}
      >
        <MenuLink href="#what-we-do" onClick={e => this.scrollToId(e, 'what-we-do')}>
          What We Do
        </MenuLink>
        <MenuLink href="#who-we-work-with" onClick={e => this.scrollToId(e, 'who-we-work-with')}>
          Who We Work With
        </MenuLink>
        <MenuLink href="#who-we-are" onClick={e => this.scrollToId(e, 'who-we-are')}>
          Who We Are
        </MenuLink>
        <MenuLink href="#lets-connect" onClick={e => this.scrollToId(e, 'lets-connect')}>
          {"Let's Connect"}
        </MenuLink>
      </Container>
    );
  }
}

RightMenu.propTypes = {
  show: PropTypes.bool,
};

RightMenu.defaultProps = {
  show: false,
};

export default RightMenu;
