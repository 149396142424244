import PropTypes from 'prop-types';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};
  align-items: stretch;
  background-color: ${props => props.backgroundColor};
`;

Row.propTypes = {
  justifyContent: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Row.defaultProps = {
  justifyContent: 'flex-start',
  backgroundColor: 'transparent',
};

export default Row;
