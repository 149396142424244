import React from 'react';

const importFonts = (fontURIs) => {
  let result = '';
  let count = 0;

  if (Array.isArray(fontURIs)) {
    result = fontURIs.map((URI) => {
      const key = count;
      count += 1;
      return (<link key={key} href={URI} rel="stylesheet" />);
    });
  }

  return result;
};

export default importFonts;
