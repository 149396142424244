import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';

import config from '../config';

const { rootFontSize, colors, fonts } = config;

const GlobalStyles = createGlobalStyle`
  body {
    color: ${colors.baseColor};
    background-color: ${colors.baseBackgroundColor};
    font-size: ${rootFontSize}px;
    font-size: ${rem(rootFontSize)};
    font-family: ${fonts.baseFontFamily};
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    margin-bottom: 8px;
    margin-bottom: ${rem('8px')};
    font-family: ${fonts.accentFontFamily};
    font-weight: 700;
    text-transform: uppercase;
  }
  h1 {
    font-size: 36px;
    font-size: ${rem('36px')};
  }
  h2 {
    font-size: 32px;
    font-size: ${rem('32px')};
  }
  h3 {
    font-size: 28px;
    font-size: ${rem('28px')};
  }
  h4 {
    font-size: 24px;
    font-size: ${rem('24px')};
  }
  h5 {
    font-size: 20px;
    font-size: ${rem('20px')};
  }
  h6 {
    font-size: 16px;
    font-size: ${rem('16px')};
  }

  p {
    margin: 0;
    margin-bottom: 16px;
    margin-bottom: ${rem('16px')};
  }
`;

export default GlobalStyles;
