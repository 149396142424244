import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import { Row, Column } from '../Grid';
import Section from './Section';
import FacebookIcon from '../../assets/svg/facebook-icon.svg';
import InstagramIcon from '../../assets/svg/instagram-icon.svg';
import MailIcon from '../../assets/svg/mail-icon.svg';

import media from '../../utils/media';
import config from '../../config';

const { colors, components } = config;
const { Column: columnConfig } = components;

const LeftContent = styled(Column)`
  display: flex;
  justify-content: flex-start;

  p {
    color: #888;
    margin: 0;
  }

  ${media.small`
    flex-basis: 100%;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    margin-bottom: ${rem('16px')};
  `}
`;

const RightContent = styled(Column)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    display: flex;
    margin: 0 8px;
    margin: 0 ${rem('8px')};

    svg {
      display: flex;
      fill: #888;
      height: 24px;
      height: ${rem('24px')};
    }

    &:hover {
      svg {
        fill: #fff;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.small`
    flex-basis: 100%;
    justify-content: center;
  `}
`;

const Footer = () => (
  <Section
    containerAs="footer"
    containerBackgroundColor={colors.dark}
    columnMaxWidth={columnConfig.maxWidth}
  >
    <Row>
      <LeftContent>
        <p>
          Copyright &copy; 2019 - Buttitta Construction, LLC
        </p>
      </LeftContent>
      <RightContent>
        <a href="https://facebook.com/ButtittaConstruction">
          <FacebookIcon />
        </a>
        <a href="https://instagram.com/ButtittaConstruction">
          <InstagramIcon />
        </a>
        <a href="mailto:contact@buttittaconstruction.com">
          <MailIcon />
        </a>
      </RightContent>
    </Row>
  </Section>
);

export default Footer;
