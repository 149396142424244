import { lighten, darken, transparentize } from 'polished';

// define theme colors
const light = darken(0.1, '#fff');
const dark = lighten(0.1, '#000');
const baseColor = dark;
const baseBackgroundColor = light;
const accentColor = '#f44336';
const transparentAccentColor = transparentize(0.6, accentColor);

// define fonts
const fontFamilyURIs = [
  'https://fonts.googleapis.com/css?family=Unica+One',
];
const baseFontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol'`;
const accentFontFamily = `'Unica One', ${baseFontFamily}`;

const config = {
  rootFontSize: 16,
  breakpoints: {
    small: 576,
    medium: 768,
    large: 992,
    xLarge: 1200,
  },
  colors: {
    light,
    dark,
    baseColor,
    baseBackgroundColor,
    accentColor,
    transparentAccentColor,
  },
  fonts: {
    fontFamilyURIs,
    baseFontFamily,
    accentFontFamily,
  },
  components: {
    Column: {
      maxWidth: '1000px',
    },
  },
};

export default config;
