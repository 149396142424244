import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import 'normalize.css';

import GlobalStyles from '../GlobalStyles';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import importFonts from '../../utils/importFonts';
import config from '../../config';

const { fonts } = config;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Layout = ({ children }) => (
  <Container>
    <Helmet>
      {importFonts(fonts.fontFamilyURIs)}
    </Helmet>
    <GlobalStyles />
    <Header />
    <Main>
      {children}
    </Main>
    <Footer />
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
