import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';

import { Row, Column } from '../Grid';
import Section from './Section';
import RightMenu from './RightMenu';

import Logo from '../../assets/svg/buttitta-construction-logo.svg';
import MenuIcon from '../../assets/svg/menu-icon.svg';
import CloseIcon from '../../assets/svg/close-icon.svg';

import config from '../../config';

const { colors } = config;

const HeaderSection = styled(Section)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const LeftNavigation = styled(Column).attrs(() => ({
  as: 'nav',
}))`
  flex-grow: 1;
  align-items: flex-start;
`;

const RightNavigation = styled(Column).attrs(() => ({
  as: 'nav',
}))`
  flex-grow: 0;
  align-items: flex-end;
  justify-content: center;
`;

const HomeLink = styled(Link)`
  display: flex;

  svg {
    display: block;
    width: auto;
    height: 48px;
    height: ${rem('48px')};
  }
`;

const MenuButton = styled.button`
  display: flex;
  padding: 8px;
  padding: ${rem('8px')};
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: block;
    width: 32px;
    width: ${rem('32px')};
    height: auto;
    fill: #fff;
  }

  &:hover {
    svg {
      fill: ${props => props.hoverFill};
    }
  }
`;

MenuButton.propTypes = {
  hoverFill: PropTypes.string,
};

MenuButton.defaultProps = {
  hoverFill: colors.accentColor || '#eee',
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRightMenu: false,
    };
  }

  toggleRightMenu = () => {
    this.setState(prevState => ({
      showRightMenu: !prevState.showRightMenu,
    }));
  }

  getCurrentMenuIcon = () => {
    const { showRightMenu } = this.state;
    if (showRightMenu) {
      return <CloseIcon />;
    }
    return <MenuIcon />;
  }

  render() {
    const { state } = this;

    return (
      <HeaderSection
        containerAs="header"
        containerBackgroundColor={colors.dark}
      >
        <Row>
          <LeftNavigation>
            <HomeLink to="/">
              <Logo />
            </HomeLink>
          </LeftNavigation>
          <RightNavigation>
            <MenuButton onClick={this.toggleRightMenu}>
              {this.getCurrentMenuIcon()}
            </MenuButton>
          </RightNavigation>
        </Row>
        <RightMenu
          show={state.showRightMenu}
        />
      </HeaderSection>
    );
  }
}

export default Header;
