import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import Section from './Section';

const MainSection = styled(Section)`
  flex: 1;
  margin-top: 80px;
  margin-top: ${rem('80px')};
`;

const Main = ({
  children,
}) => (
  <MainSection
    containerAs="main"
    containerBackgroundColor="#fff"
    containerPaddingX="0px"
    containerPaddingY="0px"
    columnMaxWidth="100%"
  >
    {children}
  </MainSection>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
